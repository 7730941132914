import React, { useEffect } from "react";
import { useState } from "react";
import "./switch.css";
import { tokenDecode } from "../../utils/helper";
import styles from "../../components/ProductViewTemplate/ProductTemplate.module.css";
import { data } from "jquery";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import Circularprogress from "../../components/Generics/Circularprogress";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import { Link } from "react-router-dom";
import editfull from "../../icons/edit.png";
import ReactPaginate from 'react-paginate';
import styled from "styled-components";
import { config } from "../../utils/config";
import Applyfilter from "../../components/Applyfilter/Applyfilter";
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ThemeMaker from "../../components/thememaker";
import Cookies from 'js-cookie';
import DeleteIcon from '@mui/icons-material/Delete';
import edit from "../../icons/edit.png";
import { Paginated } from "../../components/Paginated";



const { apiEndpoint, baseNodeapiUrl } = config;
const modalStyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 1500, bgcolor: 'background.paper', boxShadow: 24, borderRadius: "10px", p: 1, color: "black",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageContainer = styled.div
  `
  max-height: 100px;
  width: 25%;
  
  img{
    width: 100%!important;
    height: 100px;
  object-fit: contain;
}
  `

const Instorecatalog = (props) => {
  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id;
  const { permission: { product }, } = decoded?.data;
  // console.log("permissions------->", data.permission)
  const [searchValue, setsearchValue] = useState("");
  const [openInput, setopenInput] = useState(null);
  const [openQuantyInput, setopenQuantityInput] = useState(null);
  const [openReferenceIdInput, setopenReferenceIdInput] = useState(null);
  const [productlist, setproductlist] = useState([]);
  const [quickUpdatedMaxPrice2, setquickUpdatedMaxPrice2] = useState("");
  const [quickUpdatedMaxPrice, setquickUpdatedMaxPrice] = useState("");
  const [productisLoading, setproductisLoading] = useState(false);
  const [quantiy, setQuantiy] = useState("");
  const [referenceId, setreferenceId] = useState("");
  const [noproducts, setNoproducts] = useState(false);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [Alertopen, setAlertOpen] = useState(false);
  const [syncing, setsyncing] = useState(false);
  const [ConfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [nonvariatmodal, setnonvariatmodal] = useState(false);
  // console.log("ConfirmDeleteModalOpen21232", ConfirmDeleteModalOpen21232)
  const [productToDelete, setProductToDelete] = useState(null);
  const handleAlertOpen = () => {
    setOpen(false);
    setAlertOpen(true);

  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setsyncingData({
      seller_id: current_sellerid.toString(),
      price: false,
      inventory: false,
      sku: false,
      title: false,
      weight: false
    })
  };

  const [syncingData, setsyncingData] = useState({
    seller_id: current_sellerid.toString(),
    price: false,
    inventory: false,
    sku: false,
    title: false,
    weight: false

  })
  const handleSyncModalOpen = () => setOpen(true);
  const handleSyncModalClose = () => {
    setOpen(false);
    setsyncingData({
      seller_id: current_sellerid.toString(),
      price: false,
      inventory: false,
      sku: false,
      title: false,
      weight: false
    })
  }
  const syncmodalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "10px",
    boxShadow: 24,
    p: 2,
  };
  useEffect(() => {
    getproductlist();
  }, []);




  async function getproductlist() {
    let apiEndpoint = baseUrlDefiner();
    let parentId = decoded?.data?.user?.parent_id;
    let SellerId = decoded?.data?.user?.seller_id;
    let payload;
    if (parentId > 1) {
      payload = { seller_id: parentId }

    } else {
      payload = { seller_id: SellerId }

    }
    setproductisLoading(true)
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/get_seller_product`,
        method: "POST",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
        },
        data: {
          "id": []
        },
      });

      if (data.status === 200) {
        setproductisLoading(false)
        setItemOffset(0)
        setproductlist(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }


  function maxPriceHandler(id) {
    setopenInput(openInput === id ? null : id);
    const maxPriceProduct = productlist.filter((item) => item.id === id);
    setquickUpdatedMaxPrice2(...maxPriceProduct);
    let mp;
    // console.log("maxPriceProduct---", maxPriceProduct)
    if (maxPriceProduct?.tax_included_price) {
      mp = maxPriceProduct.tax_included_price;
      // console.log("mp--->", mp)
      setquickUpdatedMaxPrice(mp);
    } else if (maxPriceProduct?.mrp) {
      mp = maxPriceProduct.mrp;
      setquickUpdatedMaxPrice(mp);
    }
  }
  async function QuickUpdateHandler(id, mrp) {
    let apiEndpoint = baseUrlDefiner();

    if (parseInt(quickUpdatedMaxPrice) <= parseInt(mrp)) {
      let updatedDataToSend;
      let dataForSeller2 = {
        product_id: id,
        price: quickUpdatedMaxPrice,
        status: true,
      };
      if (current_sellerid === 1) {
        updatedDataToSend = {
          ...quickUpdatedMaxPrice2,
          tax_included_price: quickUpdatedMaxPrice,
        };
      } else {
        updatedDataToSend = dataForSeller2;
      }
      // console.log(updatedDataToSend)
      const { data } = await axios({
        url: `${apiEndpoint}/quickeditprice`,
        method: "post",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
        },
        data: updatedDataToSend,
      });

      if (data.status === 200) {
        setopenInput(null);
        toast.success("Price Updated successfully !");
        setTimeout(() => {
          getproductlist();
        }, 1000);
      }

      // if (current_sellerid===1) {
      //   if (data.status===200) {
      //     categoryListing();
      //   }
      // }
    } else {
      alert("Please Enter an amount less then MRP");

    }
  }

  function quantityModalHandler(id) {
    setopenQuantityInput(openQuantyInput === id ? null : id);

  }
  function referenceIdModalHandler(id) {
    setopenReferenceIdInput(openReferenceIdInput === id ? null : id);

  }

  async function quantityUpdateHandler(id) {
    let apiEndpoint = baseUrlDefiner();
    try {
      if (quantiy === "") {
        toast.error("Please Enter Quatity!")
      } else {
        const data = await axios({
          url: `${apiEndpoint}/updatequantity `,
          method: "POST",
          headers: {
            "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
          },
          data: {
            id: id,
            quantity: quantiy
          }
        })
        if (data.status === 200) {
          toast.success("Qunatity Updated");
          setopenQuantityInput(null);
          setQuantiy("")
          setTimeout(() => {
            getproductlist();
          }, 1000);
        } else {
          toast.error("Something Went Wrong")
        }
      }
    } catch (err) {
      console.log(err)
    }

  }
  async function ReferenceUpdateHandler(id) {
    let apiEndpoint = baseUrlDefiner();
    try {
      if (referenceId === "") {
        toast.error("Please Enter Reference Id!")
      } else {
        const data = await axios({
          url: `${apiEndpoint}/updateRefId `,
          method: "POST",
          headers: {
            "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
          },
          data: {
            id: id,
            reference_id: referenceId
          }
        })
        if (data.data.status === 200) {
          toast.success("ReferenceId Updated");
          setopenReferenceIdInput(null);
          setreferenceId("")
          setTimeout(() => {
            getproductlist();
          }, 1000);
        } else {
          toast.error("Something Went Wrong")
        }
      }
    } catch (err) {
      console.log(err)
    }

  }

  function handleSort(e) {

    if (e.target.value == 0) {
      const sortedLowToHigh = [...productlist].sort((a, b) => {
        return parseInt(a.selling_price) - parseInt(b.selling_price);
      });
      setproductlist(sortedLowToHigh);
    } else if (e.target.value == 1) {
      const sortedHightoLow = [...productlist].sort((a, b) => {
        return parseInt(b.selling_price) - parseInt(a.selling_price);
      });

      setproductlist(sortedHightoLow);
    } else {
      getproductlist();
    }
  }

  function searchHandler(e) {
    setsearchValue(e.target.value);
    if (e.target.value == "") {
      getproductlist();
      setNoproducts(false)
    }
  }

  async function searchResult(e) {
    let apiEndpoint = baseUrlDefiner();
    if (e.key == "Enter") {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/searchproductpro`,
          method: "POST",
          headers: {
            "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
          },
          data: {
            title: searchValue,
            seller_id: current_sellerid,
          },
        });
        if (data) {
          setproductlist(data?.data);
          setItemOffset(0)
          // console.log(data.data)
          if (data?.data?.length < 1) {
            setNoproducts(true)
          }
        }
      } catch (error) { }
    }

  }

  //---------------------------------------------- code  for pagination------------------------------------------------
  const [itemCheckboxes, setItemCheckboxes] = useState({});

  useEffect(() => {
    const checkboxes = {};
    productlist.forEach((item) => {
      checkboxes[item.id] = item.status;
    });
    setItemCheckboxes(checkboxes);
  }, [productlist]);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;

  // const currentItems = productlist.slice(itemOffset, endOffset);

  const [currentItems, setcurrentItems] = useState([]);

  useEffect(() => {
    let z = productlist.slice(itemOffset, endOffset)
    setcurrentItems([...z])
  }, [productlist, itemOffset])

  const pageCount = Math.ceil(productlist.length / 10);

  const handlePageClick = (event) => {
    window.scrollTo(0, 0)
    const newOffset = (event.selected * 10) % productlist.length;
    setItemOffset(newOffset);
  };

  //---------------------------------------------- code for pagination------------------------------------------------

  function updateProductList(productList) {
    setproductlist(productList)
  }

  const exportToExcel = async () => {
    let apiEndpoint = baseUrlDefiner();
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/export_product`,
        method: "POST",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
        },
      })

      const headers = Object.keys(data.message[0]);
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data.message, { header: headers });

      XLSX.utils.book_append_sheet(wb, ws, 'Orders');

      const fileName = `seller_instoreProducts.xlsx`;

      XLSX.writeFile(wb, fileName);
      toast.success("Products Exported Successfully")
    } catch (err) {
      toast.error("Server Error, please try later!")
    }
  };



  const handleImport = async () => {
    let apiEndpoint = baseUrlDefiner();
    // console.log(file)
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        console.log(data)

        let x = data.map(item => {
          return {
            reference_id: item.reference_id,
            position: item?.position,
            selling_price: item?.selling_price
          }
        })

        // console.log("xxxxxxxxxxxx------>", x)
        sendDataToDb(x)
      },
    });
  };


  async function sendDataToDb(jsonData) {
    let apiEndpoint = baseUrlDefiner();
    try {

      console.log(jsonData)
      let result = await axios({
        url: `${apiEndpoint}/updateprice`,
        method: 'POST',
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,

        },
        data: jsonData,

      })
      getproductlist();
    } catch (error) {

      // console.log("this is from catch")
      toast.error("403 error !");
    }


  }


  async function syncPriceHandler() {
    let apiEndpoint = baseUrlDefiner();
    setsyncing(true)
    try {
      const data = await axios({
        url: `${baseNodeapiUrl}/shopify/sync`,
        method: "PUT",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
        },
        data: {
          "seller_id": syncingData.seller_id,
          "price": syncingData.price.toString(),
          "inventory": syncingData.inventory.toString(),
          "sku": syncingData.sku.toString(),
          "title": syncingData.title.toString(),
          "weight": syncingData.weight.toString()
        }

      })
      if (data.status === 200 && data.data.status === 200) {
        toast.success("Product Synced Successfully");
        setAlertOpen(false);
        setsyncing(false)
        getproductlist();
        setsyncingData({
          seller_id: current_sellerid.toString(),
          price: false,
          inventory: false,
          sku: false,
          title: false,
          weight: false
        })
      } else {
        toast.error("Something Went Wrong")
        setsyncing(true)
      }
    } catch (err) {
      console.log(err)
      setsyncing(true)
    }
  }


  function syncCheckHandler(e) {
    const { name } = e.target;
    console.log(name)
    console.log(e.target.value)
    setsyncingData((prev) => {
      return {
        ...prev, [name]: !prev[name]
      }
    })
  }


  async function deleteProductHandler(item) {
    let apiEndpoint = baseUrlDefiner();
    try {
      const data = await axios({
        url: `${apiEndpoint}/deleteVarNonPro`,
        method: "POST",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
        },
        data: {
          id: item.id,
          "has_variations": item.has_variations,
        }

      })
      if (data.status === 200) {
        toast.success("Product Deleted Successfully");
        getproductlist();
        handleShipementModalClose()
      } else {
        toast.error("Something Went Wrong")

      }
    } catch (err) {
      console.log(err)
      toast.error("Something Went Wrong")
    }
  }

  async function bulkdeleteHandler(item) {
    let apiEndpoint = baseUrlDefiner();
    try {
      const data = await axios({
        url: `${apiEndpoint}/bulk_delete`,
        method: "POST",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
        },
        data: selectedBox

      })
      if (data.status === 200) {
        toast.success("Selected Product Deleted!");
        getproductlist();
        handleShipementModalClose()
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (err) {
      console.log(err)
      toast.error("Something Went Wrong")
    }
  }

  function handleShipementModalClose() {
    setConfirmDeleteModalOpen(false);
    setConfirmDeleteModalOpen(null)
  }
  function handleShipementModalClose22() {
    setnonvariatmodal(false);
    // set(null)
  }
  const [selectedBox, setselectedBox] = useState([])
  function checkHandler(row) {
    console.log("dhadha", row.id);
    let hasId = selectedBox.some((item) => item == row.id)
    if (!hasId) {
      setselectedBox((prev) => {
        return [...prev, row.id]
      })
    } else {
      setselectedBox((prev) => {
        return prev.filter((item) => item !== row.id)
      })
    }

  }

  const [masterProductUpdateState, setmasterProductUpdateState] = useState({
    data: [],
    id: ""
  })
  console.log("masterProductUpdateState---", masterProductUpdateState)
  function changeMasterProductHandler(e, index) {
    let newData = [...masterProductUpdateState.data];
    newData[index] = {
      ...newData[index],
      variation: {
        ...newData[index].variation,
        [e.target.name]: e.target.value === "true" ? true : e.target.value === "false" ? false : e.target.value
      }
    };


    // Update the state using setmasterProductUpdateState
    setmasterProductUpdateState(prevState => ({
      ...prevState,
      data: newData
    }));
  }

  function changeMasterProductHandler2(e, index) {
    let newData = [...masterProductUpdateState.data];
    newData[0] = {
      ...newData[0], [e.target.name]: e.target.value === "true" ? true : e.target.value === "false" ? false : e.target.value
    }
    setmasterProductUpdateState(prevState => ({
      ...prevState,
      data: newData
    }));
  };

  async function onMasterProductUpdateHandler() {
    let payload 
    if(masterProductUpdateState?.data[0]?.variation){
      payload = masterProductUpdateState?.data?.map(item => {
        console.log(item)
        return { ...item.variation, seller_id: current_sellerid, id: item?.variation?.seller_product_id }
      })
    }else{
      payload = masterProductUpdateState?.data?.map(item => {
        console.log(item)
        return { ...item, seller_id: current_sellerid, id: item?.seller_product_id }
      })
    }
   
    console.log(payload)
    let apiEndpoint = baseUrlDefiner();
    try {
      const data = await axios({
        url: `${apiEndpoint}/seller_instore_update_product`,
        method: "POST",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
        },
        data: {
          "allproducts": payload
        }

      })
      if (data.data.status === 200) {
        toast.success("Product Updated Successfully");

      } else {
        toast.error(data?.data?.message)

      }
    } catch (err) {
      console.log(err)
      toast.error(err?.response?.data?.message)
    }
  }

  const COLUMNS = [
    {
      Header: "Sn No.",
      Footer: "Sn No.",
      accessor: (row, count) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Image",
      Footer: "Image",
      accessor: (row) => {
        // console.log("row---->", row)
        // console.log("row?.product?.has_variations ---->", row?.product?.has_variations)
        return (
          <img
            src={row?.product?.has_variations === true ? row?.variations[0]?.variation?.image?.split(",")[0] : row?.product?.image?.split(",")[0]}
            alt="Product Image"
            height={80}
            width={80}
          />
        );
      },
      sticky: "left",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: (row) => row?.product.has_variations ? row?.product?.name : row?.product?.name,
      sticky: "left",
    },
    {
      Header: "Selling Price",
      Footer: "Selling Price",
      accessor: (row) => row?.product.has_variations ? row?.variations[0]?.variation?.selling_price : row?.product?.selling_price,
      sticky: "left",
    },
    {
      Header: "Sku",
      Footer: "Sku",
      accessor: (row) => {
        if (row?.product.has_variations === true) {
          return row?.variations[0]?.variation?.sku
        } else {
          return row?.product?.sku
        }
      },
      sticky: "left",
    },
    {
      Header: "Variations",
      Footer: "Variations",
      accessor: (row) => (row?.product.has_variations) ? "Available" : "Not Available",
      sticky: "left",
    },
    // {
    //   Header: "Status",
    //   Footer: "Status",
    //   accessor: (row) => {
    //     return <> {row?.product ? <>
    //       {row?.product?.status ? "Active" : "Draft"} </> :
    //       <>  {row.status ? "Active" : "Draft"}</>}</>
    //   },
    //   sticky: "left",
    // },

    {
      Header: "Action",
      Footer: "Action",
      accessor: (row) => {
        return (
          // <button>Action</button>
          <div >
            {row?.product.has_variations ? <img src={edit} alt="" style={{ width: "32px", height: "32px" }} onClick={() => {
              setConfirmDeleteModalOpen(true);
              setmasterProductUpdateState((prev) => {
                return { ...prev, data: row?.variations }
              })
            }} /> : <img src={edit} alt="" style={{ width: "32px", height: "32px" }} onClick={() => {
              setnonvariatmodal(true);
              setmasterProductUpdateState((prev) => {
                return { ...prev, data: [{ ...row?.product }] }
              })
            }} />}
          </div>

        );
      },
      sticky: "left",
    },
  ];
  return (
    <ThemeMaker>
      <>

        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Instore Product
                  {/* <button onClick={() => generateTemplate()}>Download </button> */}
                </h6>
              </div>
              <div className="card-body">
                <Paginated data={productlist} columns={COLUMNS} />
              </div>
            </div>
          </div>
        </div>


        <Modal open={open} onClose={handleSyncModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        >
          <Box sx={syncmodalstyle}>
            <div>
              <p className="text-center" style={{ fontSize: "20px", color: 'black', fontWeight: "500" }}>Sync Inventory</p>
            </div>

            <div>
              <div className="d-flex align-items-center mt-3" style={{ gap: "20px" }}>
                <input type="checkbox" name="price" checked={syncingData.price} style={{ width: "20px", height: "20px" }} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" style={{ fontSize: "17px", color: "black" }}>Update Price</label>
              </div>
              <div className="d-flex  align-items-center mt-3" style={{ gap: "20px" }}>
                <input type="checkbox" name="inventory" checked={syncingData.inventory} style={{ width: "20px", height: "20px" }} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" style={{ fontSize: "17px", color: "black" }}>Update Quantity</label>
              </div>
              <div className="d-flex  align-items-center mt-3" style={{ gap: "20px" }}>
                <input type="checkbox" name="title" checked={syncingData.title} style={{ width: "20px", height: "20px" }} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" style={{ fontSize: "17px", color: "black" }}>Update Title</label>
              </div>
              <div className="d-flex  align-items-center mt-3" style={{ gap: "20px" }}>
                <input type="checkbox" disabled name="sku" checked={syncingData.sku} style={{ width: "20px", height: "20px" }} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" style={{ fontSize: "17px", color: "black" }}>Update SKU</label>
              </div>
              <div className="d-flex  align-items-center mt-3" style={{ gap: "20px" }}>
                <input type="checkbox" disabled name="weight" checked={syncingData.weight} style={{ width: "20px", height: "20px" }} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" style={{ fontSize: "17px", color: "black" }}>Update Weight</label>
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-end">
              {/* <button className="btn btn-primary" onClick={() => syncPriceHandler()}>Sync</button> */}
              <button className="btn btn-primary" onClick={handleAlertOpen}>Sync</button>
            </div>

          </Box>
        </Modal>


        <Dialog
          open={Alertopen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleAlertClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              On Clicking Agree, selected parameter will be updated for the products.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAlertClose}>Disagree</Button>
            <button onClick={syncPriceHandler} className="btn btn-danger"> {syncing ? "Syncing" : "Agree"} </button>
          </DialogActions>
        </Dialog>


        {/* -------------------------------------DELETE CONFIRMATION MODAL-------------------------------------- */}
        {/* <Modal
          open={ConfirmDeleteModalOpen}
          onClose={handleShipementModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} className="col-md-4 ">

            <div className="modal-content " >
              <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Delete Product..</p>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <ReportProblemRoundedIcon style={{ fontSize: "40px", color: '#dd3737' }} />
                  <h5 style={{ fontWeight: '700' }}> Products once deleted can't be restored.
                    <h6 className="text-center font-weight-bold mt-1">Are you sure?</h6>
                  </h5>
                </div>
              </div>
              <div className="modal-footer">
                {selectedBox.length > 0 ? <button className="btn btn-danger" onClick={() => { bulkdeleteHandler() }} > Bulk Delete </button> : <button className="btn btn-danger" onClick={() => { deleteProductHandler(productToDelete) }} > Delete </button>}
              </div>
            </div>
          </Box>
        </Modal> */}

        {/* ---------------------------------------MASTER PRODUCT UPDATE MODAL----------------------------------- */}

        <Modal
          open={ConfirmDeleteModalOpen}
          onClose={handleShipementModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} className="col-md-12 ">

            <div className="modal-content " >
              <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Update Product..</p>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                </button>
              </div>
              <div className="modal-body">

                {
                  ConfirmDeleteModalOpen === true && masterProductUpdateState?.data?.map((item, index) => {
                    let { selling_price, quantity, returnable, replaceable, cancelable, return_window, seller_pickup_return, time_to_ship, cod, status } = item.variation;

                    {/* let { attribute_name, attribute_value } = item?.specifications; */ }
                    return <>
                      <p className="d-flex" style={{ gap: "10px" }}>{item?.specifications.map(item => {
                        return <p>  {item.attribute_value}</p>
                      })}</p>
                      <div className="d-flex justify-content-center mt-2" style={{ gap: "10px" }}>

                        {(selling_price || selling_price === "") && <div className="">
                          <label htmlFor="">Selling Price</label>
                          <input type="text" value={selling_price} name="selling_price" className="form-control" onChange={(e) => { changeMasterProductHandler(e, index) }} /> </div>}

                        {quantity && <div className="col-md-1">
                          <label htmlFor="">Quantity</label>
                          <input type="text" value={quantity} name="quantity" className="form-control" onChange={(e) => { changeMasterProductHandler(e, index) }} />
                        </div>}

                        {(returnable === true || returnable === false || returnable === "") && <div className="">  <label htmlFor="">Returnable</label> <select className="form-control " name="returnable" value={returnable} onChange={(e) => { changeMasterProductHandler(e, index) }}>
                          <option value={""}> Returnable</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select></div>}

                        {(cancelable === true || cancelable === false || cancelable === "") && <div className="">
                          <label htmlFor="">Cancelable</label>
                          <select className="form-control " name="cancelable" value={cancelable} onChange={(e) => { changeMasterProductHandler(e, index) }}>
                            <option value={""}>Cancelable</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select></div>}

                        {(replaceable === true || replaceable === false || replaceable === "") && <div className="">
                          <label htmlFor="">Replaceable</label>
                          <select className="form-control " name="replaceable" value={replaceable} onChange={(e) => { changeMasterProductHandler(e, index) }}>
                            <option value={""}>Replaceable</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select></div>}

                        {(return_window || return_window === "") && <div className="">
                          <label htmlFor="">Return Window</label>
                          <select className="form-control" name="return_window" value={return_window} onChange={(e) => { changeMasterProductHandler(e, index) }}>
                            <option value={""}>Return Window</option>
                            <option value="P1D">1 Day</option>
                            <option value="P2D">2 Day</option>
                          </select></div>}

                        {(seller_pickup_return === true || seller_pickup_return === false || seller_pickup_return === "") && <div >
                          <label htmlFor="">Seller pickup return</label>
                          <select className="form-control" name="seller_pickup_return" value={seller_pickup_return} onChange={(e) => { changeMasterProductHandler(e, index) }}>
                            <option value={""}>Seller pickup return</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select></div>}

                        {(time_to_ship || time_to_ship === "") && <div >
                          <label htmlFor="">Time to ship</label>
                          <select className="form-control" name="time_to_ship" value={time_to_ship} onChange={(e) => { changeMasterProductHandler(e, index) }}>
                            <option value={""}>Time to ship</option>
                            <option value="P1D">1 Day</option>
                            <option value="P2D">2 Day</option>
                          </select></div>}

                        {(cod === true || cod === false || cod === "") && <div className="">
                          <label htmlFor="">COD</label>
                          <select className="form-control " name="cod" value={cod} onChange={(e) => { changeMasterProductHandler(e, index) }}>
                            <option value={""}>COD</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select></div>}

                        {(status === true || status === false || status === "") && <div className="">
                          <label htmlFor="">Status</label>
                          <select className="form-control" name="status" value={status} onChange={(e) => { changeMasterProductHandler(e, index) }}>
                            <option value={""}>Status</option>
                            <option value={true}>Active</option>
                            <option value={false}>Draft</option>
                          </select></div>}
                      </div>   <hr /> </>
                  })
                }

              </div>
              <div className="modal-footer">

                <div className="d-flex justify-content-end">
                  <button className="btn btn-success" onClick={onMasterProductUpdateHandler}>Update</button>
                </div>

              </div>
            </div>
          </Box>
        </Modal>

        <Modal
          open={nonvariatmodal}
          onClose={handleShipementModalClose22}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} className="col-md-12 ">

            <div className="modal-content " >
              <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Update Product..</p>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose22} /></span>
                </button>
              </div>
              <div className="modal-body">

                {
                  nonvariatmodal === true && masterProductUpdateState?.data?.map((item, index) => {
                    let { selling_price, quantity, returnable, replaceable, cancelable, return_window, seller_pickup_return, time_to_ship, cod, status } = item;

                    {/* let { attribute_name, attribute_value } = item?.specifications; */ }
                    return <>
                      {/* <p className="d-flex" style={{ gap: "10px" }}>{item?.specifications.map(item => {
                        return <p>  {item.attribute_value}</p>
                      })}</p> */}
                      <div className="d-flex justify-content-center mt-2" style={{ gap: "10px" }}>

                        {(selling_price || selling_price === "") && <div className="">
                          <label htmlFor="">Selling Price</label>
                          <input type="text" value={selling_price} name="selling_price" className="form-control" onChange={(e) => { changeMasterProductHandler2(e, index) }} /> </div>}

                        {quantity && <div className="col-md-1">
                          <label htmlFor="">Quantity</label>
                          <input type="text" value={quantity} name="quantity" className="form-control" onChange={(e) => { changeMasterProductHandler(e, index) }} />
                        </div>}

                        {(returnable === true || returnable === false || returnable === "") && <div className="">  <label htmlFor="">Returnable</label> <select className="form-control " name="returnable" value={returnable} onChange={(e) => { changeMasterProductHandler2(e, index) }}>
                          <option value={""}> Returnable</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select></div>}

                        {(cancelable === true || cancelable === false || cancelable === "") && <div className="">
                          <label htmlFor="">Cancelable</label>
                          <select className="form-control " name="cancelable" value={cancelable} onChange={(e) => { changeMasterProductHandler2(e, index) }}>
                            <option value={""}>Cancelable</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select></div>}

                        {(replaceable === true || replaceable === false || replaceable === "") && <div className="">
                          <label htmlFor="">Replaceable</label>
                          <select className="form-control " name="replaceable" value={replaceable} onChange={(e) => { changeMasterProductHandler2(e, index) }}>
                            <option value={""}>Replaceable</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select></div>}

                        {(return_window || return_window === "") && <div className="">
                          <label htmlFor="">Return Window</label>
                          <select className="form-control" name="return_window" value={return_window} onChange={(e) => { changeMasterProductHandler2(e, index) }}>
                            <option value={""}>Return Window</option>
                            <option value="P1D">1 Day</option>
                            <option value="P2D">2 Day</option>
                          </select></div>}

                        {(seller_pickup_return === true || seller_pickup_return === false || seller_pickup_return === "") && <div >
                          <label htmlFor="">Seller pickup return</label>
                          <select className="form-control" name="seller_pickup_return" value={seller_pickup_return} onChange={(e) => { changeMasterProductHandler2(e, index) }}>
                            <option value={""}>Seller pickup return</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select></div>}

                        {(time_to_ship || time_to_ship === "") && <div >
                          <label htmlFor="">Time to ship</label>
                          <select className="form-control" name="time_to_ship" value={time_to_ship} onChange={(e) => { changeMasterProductHandler2(e, index) }}>
                            <option value={""}>Time to ship</option>
                            <option value="P1D">1 Day</option>
                            <option value="P2D">2 Day</option>
                          </select></div>}

                        {(cod === true || cod === false || cod === "") && <div className="">
                          <label htmlFor="">COD</label>
                          <select className="form-control " name="cod" value={cod} onChange={(e) => { changeMasterProductHandler2(e, index) }}>
                            <option value={""}>COD</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select></div>}

                        {(status === true || status === false || status === "") && <div className="">
                          <label htmlFor="">Status</label>
                          <select className="form-control" name="status" value={status} onChange={(e) => { changeMasterProductHandler2(e, index) }}>
                            <option value={""}>Status</option>
                            <option value={true}>Active</option>
                            <option value={false}>Draft</option>
                          </select></div>}
                      </div>   <hr /> </>
                  })
                }

              </div>
              <div className="modal-footer">

                <div className="d-flex justify-content-end">
                  <button className="btn btn-success" onClick={onMasterProductUpdateHandler}>Update</button>
                </div>

              </div>
            </div>
          </Box>
        </Modal>


      </>
    </ThemeMaker>
  );
};

export default Instorecatalog;
