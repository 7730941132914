import { useState, useEffect } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { config } from "../../utils/config";
import { v4 as uuidv4 } from 'uuid';
import { tokenDecode } from "../../utils/helper";

import aws from "aws-sdk";


const { apiEndpoint, awsKeyId, awsSecretKey } = config
interface ICustomer {
    id: any;

}
const Profile = () => {
    const { data } = tokenDecode();
    console.log("decode =", data);

    let params: ICustomer = useParams();
    const id = data.user.seller_id;
    const role_id = data.user.role_id;
    const user_id = data.user.id;

    const rawData = {
        id: "",
        name: "",
        email: "",
        contact_no: "",
        role_id: "",
        status: false,
    };
    const [userData, setUserData]: any = useState(rawData);
    const [userDataLoading, setUserDataLoading]: any = useState(true);

    console.log(role_id);
    const rawState = {
        "id": 7,
        "company_name": "",
        "pan_name": "",
        "pan_no": "",
        "pan_image": "",
        "gst_no": "",
        "contact_no": "",
        "street": " ",
        "city": " ",
        "area_code": " ",
        "state": " ",
        "latitude": " ",
        "longitude": " ",
        "provider_id": "",
        "personal_address": " ",
        "address_proof_type": "",
        "address_proof_image": "",
        "incorporation_date": " ",
        "upi_address": " ",
        "bank_account": " ",
        "ifsc_code": " ",
        "beneficiary_name": " ",
        "bank_name": " ",
        "branch_name": " ",
        "images": " ",
        "status": '',
        "approve_status": 0,
        "short_description": " ",
        "description": " ",
        "seller_source": " ",
        "domain": " ",
        "token": " ",
        "commission": "1",
        "ondc_commission": '',
        "fssai_licence": " ",
        "email": "",
        "name": ""
    }
    const rawErrorData = {
        "company_name": false,
        "pan_name": false,
        "pan_no": false,
        "pan_image": false,
        "gst_no": false,
        "contact_no": false,
        "street": false,
        "city": false,
        "area_code": false,
        "state": false,
        "latitude": false,
        "longitude": false,
        "provider_id": false,
        "personal_address": false,
        "incorporation_date": false,
        "upi_address": false,
        "bank_account": false,
        "ifsc_code": false,
        "beneficiary_name": false,
        "bank_name": false,
        "branch_name": false,
        "images": false,
        "status": false,
        "approve_status": false,
        "short_description": false,
        "description": false,
        "commission": false,
        "ondc_commission": false,
        "address_proof_type": false,
        "address_proof_image": false,
        "fssai_licence": false,
        "email": false,
        "name": false
    }
    const [sellerData, setSellerData] = useState(rawState);
    const [sellerDataError, setSellerDataError]: any = useState(rawErrorData);
    const errorHandler = (e: any) => {
        if (e.target.value) {
            setSellerDataError({ ...sellerDataError, [e.target.name]: "valid" });
        } else {
            setSellerDataError({ ...sellerDataError, [e.target.name]: true });
        }
    }


    const fetchSellerData = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getseller`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    seller_id: id
                }
            });
            console.log("update to data", data);

            if (data.status === 200) {
                setSellerData(data.data[0]);
            }
        } catch (error) {

        }
    }

    const inputHandler = (e: any) => {
        setSellerData({ ...sellerData, [e.target.name]: e.target.value });
    }

    const newUserCreatehandler = async () => {

    }
    const ImageHandler = async (e: any) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: awsKeyId,
                secretAccessKey: awsSecretKey
            })
            const params = {
                Bucket: "ondc-marketplace",
                Key: `sellerimages/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let { Location } = await s3.upload(params).promise();
            return Location;
        } catch (exception) {
            console.log(exception);
            return false;
        }
    };
    const uploadImage = async (e: any) => {
        const image = await ImageHandler(e);
        console.log(image);
        setSellerData({ ...sellerData, [e.target.name]: image });

    }
    const updateSellerHandlre = async () => {
        let apiEndpoint =  baseUrlDefiner();
        console.log("setSellerData", sellerData);
        const {
            company_name,
            pan_name,
            pan_no,
            pan_image,
            gst_no,
            contact_no,
            street,
            city,
            area_code,
            state,
            latitude,
            longitude,
            personal_address,
            incorporation_date,
            upi_address,
            bank_account,
            ifsc_code,
            beneficiary_name,
            bank_name,
            branch_name,
            images,
            status,
            approve_status,
            short_description,
            description,
            commission,
            ondc_commission,
            address_proof_type,
            address_proof_image,
            fssai_licence,
            email,
            name
        } = sellerData;

        if (name && email && address_proof_image && address_proof_type && ondc_commission && commission && description && short_description && short_description && images && branch_name && branch_name && beneficiary_name && ifsc_code && bank_account && upi_address && incorporation_date && personal_address && longitude && longitude && longitude && state && area_code && city && street && contact_no && gst_no && pan_no && pan_image && pan_name && company_name) {
            const { data } = await axios({
                url: `${apiEndpoint}/sellerupdate`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: sellerData
            });
            console.log("--- updated data ----");
            if (data.status === 200) {
                toast.success("Seller updated successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("403 error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            toast.error("Please fill all the fields !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const getUser = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getuser`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: { id: user_id },
            });
            console.log("user listing", data);
            if (data.status === 200) {
                setUserData(data.data);
            }
        } catch (error) { }
    };
    const userinputHandler = (e: any) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };
    const updateUserHandler = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const {
                name,
                email,
                contact_no,
            } = userData;
            if (name && email && contact_no) {


                setUserDataLoading(true);
                const { data } = await axios({
                    url: `${apiEndpoint}/userupdate`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: userData,
                });
                setUserDataLoading(false);
                if (data.status === 200) {
                    toast.success("User updated successfully !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error("404 error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill all the fields !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error("404 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };
    useEffect(() => {
        if (role_id === 1 || role_id === 2) {
            fetchSellerData();
        } else {
            getUser();
        }
    }, []);
    return (
        <>
            <ThemeMaker>

                <div className="row m-0 w-100 mt-1">
                    <div className="col-md-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Update Profile
                                </h6>
                            </div>
                            {
                                role_id === 2 || role_id === 1 ? (
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Name</label>
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        sellerDataError?.name !== "valid" && sellerDataError?.name !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="name" placeholder="Name"
                                                        value={sellerData.name}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Company name</label>
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        sellerDataError?.company_name !== "valid" && sellerDataError?.company_name !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="company_name" placeholder="Company name"
                                                        value={sellerData.company_name}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Email address</label>
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        sellerDataError?.email !== "valid" && sellerDataError?.email !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" name="email" className="form-control" placeholder="Email"
                                                        value={sellerData.email}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Contact No.</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.contact_no !== "valid" && sellerDataError?.contact_no !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="number" name="contact_no" className="form-control" placeholder="Contact number"
                                                        value={sellerData.contact_no}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >GST</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.gst_no !== "valid" && sellerDataError?.gst_no !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="gst_no" placeholder="GST number"
                                                        value={sellerData.gst_no}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}

                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >PAN Number <span className="text-danger" >*</span>
                                                        {
                                                            sellerDataError?.pan_no !== "valid" && sellerDataError?.pan_no !== false ? (
                                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                                            ) : null
                                                        }
                                                    </label>
                                                    <input type="text" className="form-control" name="pan_no" placeholder="PAN number"
                                                        value={sellerData.pan_no}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Name as per PAN  <span className="text-danger" >*</span>
                                                        {
                                                            sellerDataError?.pan_name !== "valid" && sellerDataError?.pan_name !== false ? (
                                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                                            ) : null
                                                        }
                                                    </label>
                                                    <input type="text" className="form-control" disabled={sellerData.approve_status === 1 ? true : false} name="pan_name" placeholder="Name as per pan"
                                                        value={sellerData.pan_name}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >PAN image
                                                        <a href={sellerData.pan_image} target="_black" className="small_text" > View Document</a>

                                                    </label>
                                                    <input type="file" name="pan_image" className="form-control"
                                                        onChange={(e) => uploadImage(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}


                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <p> Address Proof <span className="text-danger" >*</span>
                                                    {
                                                        sellerDataError?.address_proof_type !== "valid" && sellerDataError?.address_proof_type !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                </p>
                                                <div className="form-group">
                                                    <select name="address_proof_type" id="" className="form-control"
                                                        value={sellerData.address_proof_type}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}
                                                    >
                                                        <option value="">Select address proof</option>
                                                        <option value="Aadhar card">Aadhar card</option>
                                                        <option value="Ration card">Ration card</option>
                                                        <option value="Telephone bill">Telephone bill</option>
                                                        <option value="Bank Passbook">Bank Passbook</option>
                                                        <option value="Electricity bill">Electricity bill</option>
                                                        <option value="Lease or license agreement">Lease or license agreement</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <span> Address Proof image
                                                    <a href={sellerData.address_proof_image} target="_black" className="small_text" > View Document sasasasas</a>
                                                </span>
                                                <div className="form-group">
                                                    <input type="file" name="address_proof_image" className="form-control"
                                                        onChange={(e) => uploadImage(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <p> Incorporation date<span className="text-danger" >*</span>
                                                    {
                                                        sellerDataError?.incorporation_date !== "valid" && sellerDataError?.incorporation_date !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                </p>
                                                <div className="form-group">
                                                    <input type="date" id="start" className="form-control" name="incorporation_date"
                                                        onChange={(e) => setSellerData({ ...sellerData, [e.target.name]: e.target.value })}
                                                        value={sellerData.incorporation_date}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >Commission ( % )<span className="text-danger" > *</span>
                                                        {
                                                            sellerDataError?.commission !== "valid" && sellerDataError?.commission !== false ? (
                                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                                            ) : null
                                                        }
                                                    </label>
                                                    <input type="number" name="commission" className="form-control" placeholder="Commission"
                                                        value={sellerData.commission}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >ONDC Commission ( % )<span className="text-danger" > *</span>
                                                        {
                                                            sellerDataError?.ondc_commission !== "valid" && sellerDataError?.ondc_commission !== false ? (
                                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                                            ) : null
                                                        }
                                                    </label>
                                                    <input type="number" name="ondc_commission" className="form-control" placeholder="Commission"
                                                        value={sellerData.ondc_commission}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Fssai Licence</label>
                                                    {
                                                        sellerDataError?.fssai_licence !== "valid" && sellerDataError?.fssai_licence !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" name="fssai_licence" className="form-control" placeholder="Email"
                                                        value={sellerData.fssai_licence}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Profile
                                                        <a href={sellerData.images} target="_black" className="small_text" > View Document</a>

                                                    </label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.images !== "valid" && sellerDataError?.images !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <div className="d-flex" >
                                                        <input type="file" name="images" className="form-control"
                                                            onChange={(e) => uploadImage(e)}
                                                            onBlur={(e) => errorHandler(e)}
                                                            disabled={sellerData.approve_status === 1 ? true : false}

                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >Ondc Enabled</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.status !== "valid" && sellerDataError?.status !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <select className="form-control" name="status" id="exampleFormControlSelect1"
                                                        onChange={(e) => setSellerData({ ...sellerData, [e.target.name]: e.target.value })}
                                                        value={sellerData.status}
                                                        onBlur={(e) => errorHandler(e)}
                                                    >
                                                        <option value="true" >Active</option>
                                                        <option value="false" >Deactive</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label >Short description</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.short_description !== "valid" && sellerDataError?.short_description !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="short_description" placeholder="Short description"
                                                        value={sellerData.short_description}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label >Long description</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.description !== "valid" && sellerDataError?.description !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="description" placeholder="Long description"
                                                        value={sellerData.description}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label >Personal Address</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.personal_address !== "valid" && sellerDataError?.personal_address !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="personal_address" placeholder="Personal Address"
                                                        value={sellerData.personal_address}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <p className="font-weight-bold">Business Address </p>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >Latitude</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.latitude !== "valid" && sellerDataError?.latitude !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="latitude" placeholder="Latitude"
                                                        value={sellerData.latitude}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >Longitude</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.longitude !== "valid" && sellerDataError?.longitude !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="longitude" placeholder="Longitude"
                                                        value={sellerData.longitude}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >State</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.state !== "valid" && sellerDataError?.state !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }

                                                    <select id="State"
                                                    className="form-control" name="state"
                                                    //  placeholder="State"
                                                    value={sellerData.state}
                                                    onChange={(e) => inputHandler(e)}
                                                    onBlur={(e) => errorHandler(e)}
                                                    disabled={sellerData.approve_status === 1 ? true : false}
                                                    >
                                                        <option value="AP">Andhra Pradesh</option>
                                                        <option value="AR">Arunachal Pradesh</option>
                                                        <option value="AN">Andaman and Nicobar Islands</option>
                                                        <option value="AS">Assam</option>
                                                        <option value="BR">Bihar</option>
                                                        <option value="CG">Chhattisgarh</option>
                                                        <option value="CH">Chandigarh</option>
                                                        <option value="DH">Dadra and Nagar Haveli</option>
                                                        <option value="DD">Daman and Diu</option>
                                                        <option value="DL">Delhi</option>
                                                        <option value="GA">Goa</option>
                                                        <option value="GJ">Gujarat</option>
                                                        <option value="HR">Haryana</option>
                                                        <option value="HP">Himachal Pradesh</option>
                                                        <option value="JK">Jammu and Kashmir</option>
                                                        <option value="JH">Jharkhand</option>
                                                        <option value="KA">Karnataka</option>
                                                        <option value="KL">Kerala</option>
                                                        <option value="LD">Lakshadweep</option>
                                                        <option value="MP">Madhya Pradesh</option>
                                                        <option value="MH">Maharashtra</option>
                                                        <option value="MN">Manipur</option>
                                                        <option value="ML">Meghalaya</option>
                                                        <option value="MZ">Mizoram</option>
                                                        <option value="NL">Nagaland</option>
                                                        <option value="OR">Orissa</option>
                                                        <option value="PB">Punjab</option>
                                                        <option value="PY">Pondicherry</option>
                                                        <option value="RJ">Rajasthan</option>
                                                        <option value="SK">Sikkim</option>
                                                        <option value="TN">Tamil Nadu</option>
                                                        <option value="TS">Telangana</option>
                                                        <option value="TR">Tripura</option>
                                                        <option value="UK">Uttarakhand</option>
                                                        <option value="UP">Uttar Pradesh</option>
                                                        <option value="WB">West Bengal</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >City</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.city !== "valid" && sellerDataError?.city !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="city" placeholder="City"
                                                        value={sellerData.city}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >Area code</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.area_code !== "valid" && sellerDataError?.area_code !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="area_code" placeholder="Area code"
                                                        value={sellerData.area_code}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="form-group">
                                                    <label >Street</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.street !== "valid" && sellerDataError?.street !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="street" placeholder="Street"
                                                        value={sellerData.street}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <p className="font-weight-bold" > Bank details </p>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >Bank Name</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.bank_name !== "valid" && sellerDataError?.bank_name !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="bank_name" placeholder="Bank Name"
                                                        value={sellerData.bank_name}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >Branch name </label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.branch_name !== "valid" && sellerDataError?.branch_name !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="branch_name" placeholder="Branch name"
                                                        value={sellerData.branch_name}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >Bank account</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.bank_account !== "valid" && sellerDataError?.bank_account !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="bank_account" placeholder="Bank account"
                                                        value={sellerData.bank_account}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >IFSC code </label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.ifsc_code !== "valid" && sellerDataError?.ifsc_code !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="ifsc_code" placeholder="IFSC code"
                                                        value={sellerData.ifsc_code}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >Beneficiary name </label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.beneficiary_name !== "valid" && sellerDataError?.beneficiary_name !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="beneficiary_name" placeholder="Beneficiary name"
                                                        value={sellerData.beneficiary_name}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label >UPI address </label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.upi_address !== "valid" && sellerDataError?.upi_address !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="upi_address" placeholder="UPI address"
                                                        value={sellerData.upi_address}
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                        disabled={sellerData.approve_status === 1 ? true : false}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">


                                                <button type="submit" className="btn btn-success"
                                                    onClick={updateSellerHandlre}>
                                                    Update
                                                </button>

                                            </div>
                                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                        </div>

                                    </div>
                                ) : (
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Name</label>
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        sellerDataError?.name !== "valid" && sellerDataError?.name !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="name" placeholder="Name"
                                                        value={userData.name}
                                                        onChange={(e) => userinputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}

                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Email address</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.email !== "valid" && sellerDataError?.email !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" name="email" className="form-control" placeholder="Email"
                                                        value={userData.email}
                                                        onChange={(e) => userinputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Contact No.</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        sellerDataError?.contact_no !== "valid" && sellerDataError?.contact_no !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="number" name="contact_no" className="form-control" placeholder="Contact number"
                                                        value={userData.contact_no}
                                                        onChange={(e) => userinputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            userDataLoading ? (
                                                <button className="btn btn-success"
                                                    onClick={updateUserHandler}
                                                > Update </button>
                                            ) : (
                                                <button className="btn btn-success"

                                                > Loading... </button>
                                            )
                                        }

                                    </div>
                                )

                            }
                        </div>
                    </div>
                </div>

            </ThemeMaker>
        </>
    )
}

export default Profile;