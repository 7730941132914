// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.returnorders_ordermodalmain__Vrye5{
    width: 80%;
    position: fixed;
    top: 10%;
    height: fit-content;
    overflow-y: scroll;
    transform: translateX(-10%);
    right: 0%;
    background: #ffffff;
}

.returnorders_overlayordermodal__o9iCG{
    background: #000;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    opacity: 0.7;
}

/* .ordermodalmain div table.table-responsive thead th tr{
    max-width: unset !important;
} */

table td,
table th {
  max-width: unset !important;
}

@media (max-width:767px) {
    .returnorders_ordermodalmain__Vrye5 {
        width: 98%;
        top: 5%;
        transform: translateX(-1%);
    }
}
  `, "",{"version":3,"sources":["webpack://./src/pages/returnorders/returnorders.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,eAAe;IACf,QAAQ;IACR,mBAAmB;IACnB,kBAAkB;IAClB,2BAA2B;IAC3B,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,MAAM;IACN,QAAQ;IACR,aAAa;IACb,YAAY;AAChB;;AAEA;;GAEG;;AAEH;;EAEE,2BAA2B;AAC7B;;AAEA;IACI;QACI,UAAU;QACV,OAAO;QACP,0BAA0B;IAC9B;AACJ","sourcesContent":[".ordermodalmain{\n    width: 80%;\n    position: fixed;\n    top: 10%;\n    height: fit-content;\n    overflow-y: scroll;\n    transform: translateX(-10%);\n    right: 0%;\n    background: #ffffff;\n}\n\n.overlayordermodal{\n    background: #000;\n    width: 100%;\n    position: fixed;\n    top: 0;\n    right: 0;\n    height: 100vh;\n    opacity: 0.7;\n}\n\n/* .ordermodalmain div table.table-responsive thead th tr{\n    max-width: unset !important;\n} */\n\ntable td,\ntable th {\n  max-width: unset !important;\n}\n\n@media (max-width:767px) {\n    .ordermodalmain {\n        width: 98%;\n        top: 5%;\n        transform: translateX(-1%);\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ordermodalmain": `returnorders_ordermodalmain__Vrye5`,
	"overlayordermodal": `returnorders_overlayordermodal__o9iCG`
};
export default ___CSS_LOADER_EXPORT___;
